<template>
    <modal
        v-if="noProfileEnabled"
        id="vehicle-of-interest-modal"
        hide-footer
        body-class="p-2"
        size="lg"
        :aria-label="ariaLabel"
    >
        <div v-if="loading" class="d-flex flex-column align-items-center">
            <b-spinner class="mb-3" />
            <h2>Loading...</h2>
        </div>

        <div v-else class="d-flex flex-column align-items-center position-relative">
            <div class="d-flex flex-column flex-md-row align-items-center mb-3">
                <div class="vehicle-image mb-3 mb-md-0 mr-0 mr-md-3">
                    <vehicle-image
                        :src="imageUrl"
                        :stock-src="stockImageUrl"
                        :body-style="bodyStyle"
                        :alt="vehicleName"
                    />
                </div>

                <div class="d-flex flex-column text-center mb-3 mb-md-0">
                    <div class="ymm">{{ vehicleName }}</div>
                    <div class="trim">{{ trim }}</div>
                    <div v-if="exteriorColor" class="ex-color">Exterior: {{ exteriorColor }}</div>
                    <div v-if="interiorColor" class="in-color">Interior: {{ interiorColor }}</div>
                </div>
            </div>

            <div class="button-block mb-3 w-100">
                <primary-btn :loading="loadingPrimaryCta" class="w-100 mb-2" block @click.native="estimatePayments">
                    {{ $t("vehicleOfInterestModal.mainCtaButton") }}
                </primary-btn>
                <primary-btn :loading="loadingSecondaryCta" class="w-100 mb-2" block @click.native="createAccountClick">
                    {{ $t("vehicleOfInterestModal.secondaryCtaButton") }}
                </primary-btn>
                <div class="d-flex justify-content-center text-center pt-2">
                    <div class="description-text">{{ $t("vehicleOfInterestModal.secondaryCtaDescription") }}</div>
                </div>
            </div>

            <div class="disclaimer-box text-center d-flex flex-column align-items-center">
                <div class="disclaimer mb-3 mt-2">{{ $t("vehicleOfInterestModal.disclaimer") }}</div>

                <div class="learn-more-link mb-2">
                    <a aria-label="Learn more about the many benefits of creating an account" @click="closeModal">
                        Learn More</a
                    >
                </div>
            </div>

            <div class="logo-block position-absolute">
                <div class="logo-img"></div>
            </div>
        </div>
    </modal>
</template>
<script>
import api from "@/api";
import VehicleImage from "Components/VehicleImage";
import Modal from "Components/Modal";
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
import lodashGet from "lodash/get";
import lodashIsNil from "lodash/isNil";

export default {
    name: "VehicleOfInterestModal",
    components: { PrimaryBtn, Modal, VehicleImage },
    props: {
        vehicleId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            vehicle: null,
            loadingPrimaryCta: false,
            loadingSecondaryCta: false,
            loadingSignInCta: false,
        };
    },
    computed: {
        imageUrl() {
            return lodashGet(this.vehicle, "imageUrl", null);
        },
        stockImageUrl() {
            return lodashGet(this.vehicle, "stockImageUrl", null);
        },
        bodyStyle() {
            return lodashGet(this.vehicle, "bodyStyle", "sedan");
        },
        vehicleName() {
            let year = lodashGet(this.vehicle, "year", "");
            let make = lodashGet(this.vehicle, "make", "");
            let model = lodashGet(this.vehicle, "model", "");
            return year + " " + make + " " + model;
        },
        trim() {
            return lodashGet(this.vehicle, "trim", null);
        },
        exteriorColor() {
            return lodashGet(this.vehicle, "exteriorColor", null);
        },
        interiorColor() {
            return lodashGet(this.vehicle, "interiorColor", null);
        },
        ariaLabel() {
            return `Vehicle of Interest ${this.vehicleName}`;
        },
        noProfileEnabled() {
            return this.$page.isEnabled("no-profile");
        },
    },
    mounted() {
        this.fetchVehicle();
    },
    methods: {
        createAccountClick() {
            this.loadingSecondaryCta = true;
            this.$gtm.trackEvent({
                event: "mixpanel",
                name: "VehicleOfInterestModal - Unlock all Buy@Home Benefits - Click",
            });
            this.$gtm.trackEvent({
                category: "VehicleOfInterestModal",
                action: "click",
                label: "Unlock all Buy@Home Benefits",
                noninteraction: false,
            });
            this.$carsaverEventTracker("trackCreateAccountClickAction");
            document.location = "/account/register/ghost";
        },
        estimatePayments() {
            this.loadingPrimaryCta = true;

            this.$gtm.trackEvent({
                event: "mixpanel",
                name: "VehicleOfInterestModal - Estimate Payments - Click",
            });
            this.$gtm.trackEvent({
                category: "VehicleOfInterestModal",
                action: "click",
                label: "Estimate Payments",
                noninteraction: false,
            });
            try {
                this.$carsaverEventTracker("trackEstimatePaymentAction");
            } catch (error) {
                console.error("An error occurred while tracking estimate payment action:", error);
            }

            document.location = `/no-profile/vehicles/${this.vehicleId}`;
        },
        fetchVehicle() {
            if (!lodashIsNil(this.vehicleId)) {
                api.get(`/vehicles/${this.vehicleId}/modal`)
                    .then((response) => {
                        this.vehicle = response.data;
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error(error);
                        this.loading = false;
                    });
            }
        },
        closeModal() {
            this.$carsaverEventTracker("trackLearnMoreAction");
            this.$bvModal.hide("vehicle-of-interest-modal");
        },
    },
};
</script>
<style lang="scss">
#vehicle-of-interest-modal {
    .vehicle-image {
        width: 275px;
        @include media-breakpoint-up(md) {
            width: 300px;
        }

        .deal-image {
            i {
                font-size: px2rem(60);
                padding: 15px;
            }
        }
    }

    .ymm {
        color: $gray-800;
        font-size: px2rem(18);
        font-weight: bold;
        letter-spacing: 0;
        line-height: px2rem(22);
    }

    .trim {
        color: $gray-800;
        font-size: px2rem(14);
        letter-spacing: 0;
        line-height: px2rem(17);
    }

    .ex-color,
    .in-color {
        color: $gray-600;
        font-size: px2rem(12);
        line-height: px2rem(14);
    }

    .vin {
        color: rgba(46, 46, 46, 0.58);
        font-size: px2rem(12);
        letter-spacing: 0;
        line-height: px2rem(14);
    }

    .button-block {
        max-width: 308px;

        .description-text {
            max-width: 255px;
            font-size: px2rem(12);
        }
    }

    .disclaimer-box {
        width: 100%;
        color: $black;
        background: $gray-200;
        margin-bottom: 50px;
        border-radius: 3px;

        .disclaimer {
            font-weight: bold;
            font-size: px2rem(12);
            width: 300px;
        }

        @include media-breakpoint-up(md) {
            .disclaimer {
                width: 400px;
            }
        }
    }

    .learn-more-link {
        a {
            font-size: px2rem(12);
            letter-spacing: 0;
            line-height: px2rem(16);
            @include themify($themes) {
                color: themed("primary-color");
            }
        }
    }

    .logo-block {
        bottom: 8px;
        right: 10px;
        height: 20px;
        width: 85px;

        .logo-img {
            @include themify($themes) {
                background-image: themed("vehicle-of-interest-modal-logo");
            }
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
        }

        @include media-breakpoint-up(lg) {
            bottom: 10px;
            right: 20px;
            height: 25px;
            width: 110px;
        }
    }
}
</style>
