<template>
    <div>
        <b-container fluid class="warranty-container">
            <b-container class="desktop-text-block d-none d-md-block">
                <b-row>
                    <b-col cols="12">
                        <div class="d-flex flex-column">
                            <h1 class="mb-1">{{ title }}</h1>
                            <h2 class="mb-3">
                                {{ subtitle }}
                            </h2>

                            <div class="msg mb-4">
                                {{ msg }}
                            </div>

                            <get-started-btn class="learn-more-btn" :text="buttonText" />
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>

        <b-container class="d-block d-md-none">
            <b-row>
                <b-col cols="12">
                    <div class="d-flex flex-column px-3 mobile-text-block">
                        <h1 class="mb-1">{{ title }}</h1>
                        <h2 class="mb-3">
                            {{ subtitle }}
                        </h2>

                        <div class="msg mb-4">
                            {{ msg }}
                        </div>
                        <get-started-btn class="learn-more-btn" :text="buttonText" />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import GetStartedBtn from "./GetStartedBtn";
export default {
    components: { GetStartedBtn },
    data() {
        return {
            title: "Peace of Mind.",
            subtitle: "Included with CarSaver Lifetime Warranty.",
            msg:
                "Experience the joy of worry-free driving when you purchase and new or used vehicle. " +
                "with CarSaver's exclusive lifetime warranty that covers your car for unlimited miles " +
                "and unlimited time for as long as you own the car, and at no cost to you.",
            buttonText: "Learn More",
        };
    },
};
</script>
<style lang="scss" scoped>
.warranty-container {
    min-height: 220px;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    @include themify($themes) {
        background-image: themed("warranty-img-sm");
    }

    @include media-breakpoint-up("md") {
        min-height: 508px;
        @include themify($themes) {
            background-image: themed("warranty-img-md");
        }
    }

    @include media-breakpoint-up("lg") {
        @include themify($themes) {
            background-image: themed("warranty-img-lg");
        }
    }
}

.warranty-container {
    .desktop-text-block {
        padding-top: 115px;
        color: $white;

        h1 {
            font-size: px2rem(28);
            line-height: px2rem(30);
            font-weight: bold;
            margin-bottom: 0;
        }
        h2 {
            font-size: px2rem(26);
            line-height: px2rem(28);
            font-style: oblique;
            margin-bottom: 0;
        }

        .msg {
            font-size: px2rem(16);
            line-height: px2rem(19);
            max-width: 535px;
        }

        .learn-more-btn {
            width: 145px;
        }
    }
}

.mobile-text-block {
    padding-top: 38px;
    padding-bottom: 38px;
    h1 {
        font-size: px2rem(30);
        line-height: px2rem(34);
        font-weight: bold;
        margin-bottom: 0;
        color: $gray-800;
    }
    h2 {
        font-size: px2rem(28);
        line-height: px2rem(34);
        font-style: oblique;
        margin-bottom: 0;
        color: $gray-800;
    }

    .msg {
        font-size: px2rem(16);
        line-height: px2rem(19);
        max-width: 535px;
    }
}
</style>
