<template>
    <div class="save-container">
        <div class="image">
            <b-container class="d-none d-sm-flex justify-content-end">
                <div class="contents">
                    <h3 class="mb-2">Save Your Work</h3>
                    <p>
                        Save your deals and favorite vehicles to your personal garage to compare and come back to them
                        later.
                    </p>
                    <div class="cta">
                        <get-started-btn cs-tracker-event="saveYourWorkGetStarted" />
                    </div>
                </div>
            </b-container>
        </div>
        <div class="contents d-sm-none px-4">
            <h3 class="mt-4 mb-2">Save Your Work</h3>
            <p>Save your deals and favorite vehicles to your personal garage to compare and come back to them later.</p>
        </div>
    </div>
</template>

<script>
import getStartedBtn from "./GetStartedBtn";
export default {
    components: { getStartedBtn },
};
</script>

<style lang="scss">
.save-container {
    width: 100%;

    .image {
        background: url("../images/savework_bg.jpg") no-repeat top;
        background-size: cover;
        height: 194px;
        width: 100%;

        @include media-breakpoint-up("sm") {
            background: url("../images/savework_bg_lg.jpg") no-repeat top right;
            height: 500px;
        }

        .contents {
            padding-top: 110px;
            text-align: left;
            max-width: 360px;

            h3 {
                color: $gray-800;
                font-size: px2rem(36);
                font-weight: bold;
                line-height: px2rem(43);
            }
            .cta {
                button {
                    width: 190px;
                }
            }
        }
    }
    .contents {
        color: $gray-700;
        font-size: px2rem(16);
        line-height: px2rem(24);
        text-align: center;

        h3 {
            color: $gray-800;
            font-size: px2rem(36);
            font-weight: bold;
            line-height: px2rem(40);
        }
    }
}
</style>
