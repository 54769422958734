const getCsrfToken = (metaName) => {
    return getMeta("_csrf");
};

const getMeta = (metaName) => {
    const metas = document.getElementsByTagName("meta");

    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute("name") === metaName) {
            return metas[i].getAttribute("content");
        }
    }

    return null;
};

export default {
    getCsrfToken,
    getMeta,
};
