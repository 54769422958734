<template>
    <section class="view-all-inventory-container">
        <!-- eslint-disable vue/no-v-html -->
        <h2 v-html="$t('home.inventorySection.title')" />
        <div class="list-container">
            <div v-for="item in $t('home.inventorySection.inventory')" :key="item.title" class="list-item">
                <div :class="['small', !isError ? 'slide-up-transition' : '', isNissan ? 'nissan-cars' : 'bmw-cars']">
                    <div
                        role="button"
                        tabindex="0"
                        :class="[item.imgClass, 'img-style']"
                        @keydown.space.enter.prevent="handleClick(item.filters)"
                        @click="handleClick(item.filters)"
                    >
                        &#8203;
                    </div>
                </div>
                <div class="item-content">
                    <p>{{ item.title }}</p>
                    <div
                        role="button"
                        tabindex="0"
                        @click="handleClick(item.filters)"
                        @keydown.space.enter.prevent="handleClick(item.filters)"
                    >
                        {{ item.linkText }}
                    </div>
                </div>
            </div>
        </div>
        <primary-btn class="primary-btn-style" @click.native="handleClick({})">
            {{ $t("home.inventorySection.primaryCtaText") }}
        </primary-btn>
    </section>
</template>

<script>
import { defineComponent } from "vue";
import PrimaryBtn from "Components/Buttons/PrimaryBtn.vue";
import MfaRedirectionMixin from "Modules/Home/components/Home/MfaRedirectionMixin.vue";
import { clientSessionStorage } from "@/libs/clientStorage";

export default defineComponent({
    name: "ViewAllInventory",
    components: { PrimaryBtn },
    mixins: [MfaRedirectionMixin],
    props: {
        isError: {
            type: Boolean,
            default: false,
            required: false,
        },
        isNissan: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    methods: {
        handleClick(filters) {
            clientSessionStorage.setItem("SRP_FILTERS", JSON.stringify(filters));
            this._mixin_goToPageAction(); // MODIFIED IN MfaRedirectionMixin
        },
    },
});
</script>

<style scoped lang="scss">
$bmw-car-types: (
    home-suv: "home-suv",
    home-coupe-convertible: "home-coupe-convertible",
    home-sedan: "home-sedan",
    home-ev: "home-ev",
);
$nissan-car-types: (
    nissan-home-cross-suv: "nissan-home-cross-suv",
    nissan-home-car: "nissan-home-car",
    nissan-home-trucks: "nissan-home-trucks",
    nissan-home-ev: "nissan-home-ev",
);

div[role="button"] {
    cursor: pointer;
}

.view-all-inventory-container {
    background: #f6f6f6;
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #535353;

    h2 {
        text-align: center;
        font-size: px2rem(36);
        margin-bottom: 16px;
        color: $secondary-gray;

        @include media-breakpoint-down("sm") {
            font-size: px2rem(28);
        }
    }

    .list-container {
        display: grid;
        grid-template-columns: repeat(4, minmax(289px, 349px));
        overflow: hidden;

        max-width: 1408px;

        @include media-breakpoint-down("lg") {
            width: 100%;
            max-width: 960px;
            grid-template-columns: repeat(2, minmax(289px, 478px));
            place-content: space-between;
            place-items: center;
            row-gap: 8px;
        }

        @include media-breakpoint-down("sm") {
            width: 100%;
            max-width: 343px;
            grid-template-columns: repeat(1, minmax(289px, 330px));
            place-content: space-between;
            justify-content: center;
            row-gap: 8px;
        }

        .list-item {
            .item-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
                p {
                    margin: 0;
                    font-size: px2rem(22);
                    text-align: center;
                    @include media-breakpoint-down("sm") {
                        font-size: px2rem(18);
                    }
                }
                & > div {
                    text-align: center;
                    display: block;
                    font-size: px2rem(16);
                    text-decoration: none;
                    @include themify($themes) {
                        border-bottom: 1px solid themed("primary-color");
                        color: themed("primary-color");
                    }
                }
            }

            .img-style {
                width: 330px;
            }

            div {
                display: grid;
                place-items: center;
            }

            div.bmw-cars {
                @each $class, $theme in $bmw-car-types {
                    .#{$class} {
                        background-size: cover;
                        display: block;
                        width: 330px;
                        height: 172px;
                        transition: transform 0.3s ease;
                        cursor: pointer;
                        @include themify($themes) {
                            background-image: themed("#{$theme}");
                        }

                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }
            }

            div.nissan-cars {
                @each $class, $theme in $nissan-car-types {
                    .#{$class} {
                        background-size: cover;
                        display: block;
                        width: 300px;
                        height: 172px;
                        transition: transform 0.3s ease;
                        cursor: pointer;
                        @include themify($themes) {
                            background-image: themed("#{$theme}");
                        }

                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }
            }
        }
    }

    .primary-btn-style {
        display: block;
        margin-top: 40px;
        max-width: 303px;
        width: 100%;

        button {
            width: 100%;
        }
    }
}
</style>
