<template>
    <div>
        <b-container fluid class="new-car-container">
            <b-container class="desktop-text-block d-none d-md-block">
                <b-row>
                    <b-col cols="12">
                        <div class="d-flex flex-column">
                            <!-- eslint-disable vue/no-v-html -->
                            <h2 class="mb-3" v-html="title"></h2>

                            <!-- eslint-disable vue/no-v-html -->
                            <div class="msg mb-3" v-html="subtitle"></div>

                            <!-- eslint-disable vue/no-v-html -->
                            <div class="msg" v-html="msg"></div>

                            <get-started-btn class="learn-more-btn" :text="buttonText" />
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>

        <b-container class="d-block d-md-none">
            <b-row>
                <b-col cols="12">
                    <div class="d-flex flex-column px-3 mobile-text-block">
                        <!-- eslint-disable vue/no-v-html -->
                        <h2 class="mb-3" v-html="title"></h2>

                        <!-- eslint-disable vue/no-v-html -->
                        <div class="msg mb-3" v-html="subtitle"></div>

                        <!-- eslint-disable vue/no-v-html -->
                        <div class="msg" v-html="msg"></div>

                        <get-started-btn class="learn-more-btn" :text="buttonText" />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import GetStartedBtn from "./GetStartedBtn";
export default {
    components: { GetStartedBtn },
    computed: {
        title() {
            return this.$t("home.newCar.title");
        },
        subtitle() {
            return this.$t("home.newCar.title");
        },
        msg() {
            return this.$t("home.newCar.msg");
        },
        buttonText() {
            return this.$t("home.newCar.buttonText");
        },
    },
};
</script>
<style lang="scss" scoped>
.new-car-container {
    min-height: 220px;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    @include themify($themes) {
        background-image: themed("new-car-sm");
    }

    @include media-breakpoint-up("md") {
        min-height: 508px;
        @include themify($themes) {
            background-image: themed("new-car-md");
        }
    }

    @include media-breakpoint-up("lg") {
        @include themify($themes) {
            background-image: themed("new-car-lg");
        }
    }
}

.new-car-container {
    .desktop-text-block {
        max-width: 720px;
        padding-top: 110px;
        color: $gray-800;

        h2 {
            font-size: px2rem(36);
            line-height: 1.3;
            font-weight: bold;
            margin-bottom: 0;
            max-width: 320px;
        }

        .msg {
            font-size: px2rem(16);
            line-height: 1.3;
            max-width: 385px;
        }

        .learn-more-btn {
            margin-top: 30px;
            width: 250px;
        }
    }
}

.mobile-text-block {
    padding-top: 38px;
    padding-bottom: 62px;
    h2 {
        font-size: px2rem(28);
        line-height: 1.3;
        font-weight: bold;
        margin-bottom: 0;
        color: $gray-800;
    }

    .learn-more-btn {
        margin-top: 30px;
        width: 100%;
    }

    .msg {
        font-size: px2rem(16);
        line-height: 1.3;
        max-width: 535px;
    }
}
</style>
