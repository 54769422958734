import Vue from "vue";
import Vuex from "vuex";
import UpgradePlugin from "@/libs/UpgradePlugin";
import pathify from "@/store/pathify";
import campaign from "@/store/modules/campaign";
import context from "@/store/modules/context";
import account from "Modules/Account/store";
import Layout from "Components/Layout";
import NoLayout from "Components/NoLayout";
import router from "Modules/Home/router";
import { i18n } from "@/libs/i18nSetup";
import lodashGet from "lodash/get";
import lodashIsNil from "lodash/isNil";

const environment = lodashGet(window, "_APP_CONFIG.env[0]", "local") || "local";
const debug = environment !== "prod";

Vue.use(UpgradePlugin);

const store = new Vuex.Store({
    plugins: [pathify.plugin],

    modules: {
        account,
        campaign,
        context,
    },
    strict: debug,
});

new Vue({
    i18n,
    store,
    router,
    render: (h) => {
        const campaignTheme = lodashGet(campaign, "state.theme");

        return lodashIsNil(campaignTheme) ? h(NoLayout) : h(Layout);
    },
}).$mount("#app");

Vue.loadScript("https://fast.wistia.com/embed/medias/xc155jiqwn.jsonp");
Vue.loadScript("https://fast.wistia.com/assets/external/E-v1.js");
