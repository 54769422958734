<template>
    <b-container class="container-hero d-flex align-items-center" fluid>
        <b-container class="p-0 align-items-center">
            <PinFormContainer ref="pinFormContainer" />
        </b-container>
    </b-container>
</template>
<script>
import { defineComponent } from "vue";
import PinFormContainer from "Modules/Home/components/Home/ContainerHero/PinFormContainer.vue";

export default defineComponent({
    name: "ContainerHero",
    components: { PinFormContainer },
    methods: {
        getStartedAction() {
            this.$refs.pinFormContainer.getStartedAction();
        },
    },
});
</script>
<style lang="scss" scoped>
.container-hero {
    min-height: 508px;
    width: 100%;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-down("sm") {
        @include themify($themes) {
            background-image: themed("container-hero-mobile");
        }
    }

    @include media-breakpoint-up("sm") {
        @include themify($themes) {
            background-image: themed("container-hero-sm");
        }
    }

    @include media-breakpoint-up("md") {
        @include themify($themes) {
            background-image: themed("container-hero-md");
        }
    }

    @include media-breakpoint-up("lg") {
        @include themify($themes) {
            background-image: themed("container-hero-lg");
        }
    }
}
</style>
