<template>
    <div class="mobile-sticky-footer d-flex d-sm-none">
        <div class="d-flex flex-column w-100 footer-block p-3">
            <primary-btn v-if="vehicleId" class="mb-2" @click.native="estimatePaymentsClick">
                {{ $t("landingPageFloatingFooter.mainCtaButton") }}
            </primary-btn>

            <b-button :variant="vehicleId ? 'secondary' : 'primary'" @click="createAccountClick">
                {{ $t("landingPageFloatingFooter.secondaryCtaButton") }}
            </b-button>
        </div>
    </div>
</template>
<script>
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
import CampaignProductEnum from "@/libs/CampaignProductEnum";
import lodashGet from "lodash/get";
import { get } from "vuex-pathify";

export default {
    name: "MobileFooter",
    components: { PrimaryBtn },
    props: {
        vehicleId: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        featureFlags: get("context/featureFlags"),
        productId: get("campaign/productId"),
        isGhostAccountRegisterFeature() {
            const result = lodashGet(this.featureFlags, "GHOST_ACCOUNT_REGISTER_FEATURE", false) || false;
            return result;
        },
        isECommerceProduct() {
            const result = this.productId === CampaignProductEnum.ECOMMERCE;
            return result;
        },
    },

    methods: {
        createAccountClick() {
            this.$gtm.trackEvent({
                event: "mixpanel",
                name: "LandingPageMobileFooter - Create Account - Click",
            });
            this.$gtm.trackEvent({
                category: "LandingPageMobileFooter",
                action: "click",
                label: "Create Your Nissan Account",
                noninteraction: false,
            });

            if (this.isGhostAccountRegisterFeature === true && this.isECommerceProduct) {
                document.location = "/account/register/ghost";
            } else {
                document.location = "/account/register";
            }
        },
        estimatePaymentsClick() {
            this.$gtm.trackEvent({
                event: "mixpanel",
                name: "LandingPageMobileFooter - Estimate Payments - Click",
            });
            this.$gtm.trackEvent({
                category: "LandingPageMobileFooter",
                action: "click",
                label: "Estimate Payments",
                noninteraction: false,
            });

            document.location = `/no-profile/vehicles/${this.vehicleId}`;
        },
    },
};
</script>

<style lang="scss">
//to prevent covering up main footer
.main-footer {
    @include media-breakpoint-up(md) {
        margin-bottom: inherit;
    }
    @include media-breakpoint-down(sm) {
        padding-bottom: px2rem(140);
    }
}

.mobile-sticky-footer {
    position: fixed;
    bottom: 162px;
    width: 100%;
    z-index: 1030;
    transition: 0.5s;
    transition-timing-function: ease-in;
    transform: translateY(130%);

    .footer-block {
        flex-flow: row nowrap;
        height: 125px;
        background-color: $white;

        .btn-primary,
        .btn-secondary {
            width: 100%;
            white-space: nowrap;
        }
    }
}
</style>
