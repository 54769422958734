<template>
    <validation-observer ref="form" v-slot="{ passes }">
        <b-form
            ref="pinForm"
            class="pin-form"
            method="post"
            novalidate
            action="/"
            @submit.stop.prevent="passes(submitForm)"
        >
            <div class="d-flex align-items-center" :class="{ 'mb-3': !hasErrorMsg }">
                <input type="hidden" name="_csrf" :value="csrf" />
                <b-form-input
                    id="pin"
                    ref="pin"
                    v-model="pin"
                    aria-labelledby="pin-label"
                    :class="{ active: pinNotEmpty }"
                    name="pin"
                    :required="true"
                    :maxlength="maxPinLength"
                />
                <primary-btn
                    id="pin-submit"
                    ref="pinSubmit"
                    type="submit"
                    class="ml-3"
                    @click.stop.prevent.native="submitForm"
                >
                    Go
                </primary-btn>
            </div>
            <div
                id="pin-invalid-feedback"
                ref="pinInvalidFeedback"
                :class="{ 'd-block': hasErrorMsg }"
                class="invalid-feedback mb-1"
            >
                PIN not valid, please try again.
            </div>
        </b-form>
    </validation-observer>
</template>

<script>
import csrfUtils from "@/libs/csrf";
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
import { default as clientStorage } from "@/libs/clientStorage";
import lodashGet from "lodash/get";

export default {
    components: { PrimaryBtn },
    data() {
        return {
            isLoading: false,
            pin: lodashGet(this.$route.query, "pin") || "",
            hasErrorMsg: false,
            inputState: null,
            maxPinLength: 8,
            minPinLength: 5,
        };
    },

    computed: {
        pinNotEmpty() {
            return this.pin.length > 0;
        },
        csrf() {
            return csrfUtils.getCsrfToken();
        },
        hasPinError() {
            return lodashGet(this.$route.query, "error");
        },
        hasUrlPin() {
            return lodashGet(this.$route.query, "pin");
        },
    },

    mounted() {
        this.$refs.pin.focus();

        if (this.hasPinError) {
            this.handleUrlErrorParam();
        }

        if (this.hasUrlPin) {
            this.validatePin();
        }
    },

    methods: {
        validatePin() {
            const maxLength = this.maxPinLength;
            const minLength = this.minPinLength;
            const pinLength = this.pin.length;
            const form = this.$refs.pinForm;
            let errMsg = "";
            let isValid = false;

            if (pinLength > maxLength) {
                errMsg = "PIN must be a maximum of 6 characters in length.";
                this.setPinError(errMsg);
            } else if (pinLength < minLength) {
                errMsg = "PIN must be a minimum of 5 characters in length.";
                this.setPinError(errMsg);
            } else {
                isValid = true;
            }

            if (isValid) {
                form.classList.add("was-validated");
            }

            return isValid;
        },

        setPinError(msg) {
            this.isLoading = false;
            this.setPinErrorMsg(msg);
            this.inputState = true;
        },

        submitForm() {
            const form = this.$refs.pinForm;
            this.isLoading = true;
            clientStorage.removeItem("mfaCode");

            if (this.validatePin()) {
                form.classList.add("was-validated");
                form.submit();
            }
        },

        getUrlErrorMsg() {
            const urlParams = new URLSearchParams(window.location.search);
            const hasError = urlParams.has("error");
            let errorMsg = "";
            let error = "";

            if (hasError) {
                this.hasErrorMsg = true;
                error = urlParams.get("error");

                switch (error) {
                    case "not-found":
                        errorMsg = "PIN not found, please try again.";
                        break;
                    case "pin-missing":
                        errorMsg = "PIN can not be empty.";
                        break;
                    default:
                        errorMsg = "Something went wrong, please try again.";
                }
            }

            return errorMsg;
        },

        setPinErrorMsg(errorMsg) {
            this.hasErrorMsg = true;
            const pinInvalidFeedback = this.$refs.pinInvalidFeedback;

            pinInvalidFeedback.innerHTML = errorMsg;
            this.$refs.pin.$el.classList.add("is-invalid");
        },

        handleUrlErrorParam() {
            const errorMsg = this.getUrlErrorMsg();

            if (typeof errorMsg === "string" && errorMsg.length > 0) {
                this.setPinErrorMsg(errorMsg);
            }
        },
    },
};
</script>
<style lang="scss">
.pin-form {
    input#pin {
        background-color: rgba(238, 238, 238, 0.45);
        border: none;
        color: black;
        font-size: px2rem(24);

        @include themify($themes) {
            background-color: themed("pin-input-background-inactive");
        }

        &:focus,
        &.active {
            background-color: rgba(238, 238, 238, 0.85);

            @include themify($themes) {
                background-color: themed("pin-input-background-active");
            }
        }
    }

    input#pin,
    button#pin-submit {
        height: 40px;
    }

    #pin-invalid-feedback {
        text-shadow: 0px 0px 20px white;
        font-size: 0.75rem;
    }
}
</style>
