<template>
    <div class="finance-container">
        <div class="image">
            <b-container class="d-none d-sm-block">
                <div class="contents d-none d-sm-block">
                    <h3 class="mb-2">Select Your<br />Financing Options</h3>
                    <p>
                        Review lease and financing options in real time with no obligation – and no impact to your
                        credit score.
                    </p>
                    <div class="cta">
                        <get-started-btn cs-tracker-event="financingOptionsGetStarted" message="Apply for Financing" />
                    </div>
                </div>
            </b-container>
        </div>
        <div class="contents d-sm-none px-4">
            <h3 class="mt-4 mb-2">Select Your<br />Financing Options</h3>
            <p>
                Review lease and financing options in real time with no obligation – and no impact to your credit score.
            </p>
        </div>
    </div>
</template>

<script>
import GetStartedBtn from "./GetStartedBtn";

export default {
    components: { GetStartedBtn },
};
</script>

<style lang="scss">
.finance-container {
    width: 100%;

    .image {
        background: url("../images/finance_bg.jpg") no-repeat top;
        background-size: cover;
        height: 194px;
        width: 100%;
        position: relative;

        @include media-breakpoint-up("sm") {
            background: url("../images/finance_bg_lg.jpg") no-repeat top left;
            background-size: cover;
            height: 500px;
        }

        .contents {
            position: absolute;
            top: 150px;
            text-align: left;
            max-width: 430px;

            h3 {
                color: $gray-800;
                font-size: px2rem(36);
                font-weight: bold;
                line-height: px2rem(43);
            }
            .cta {
                button {
                    width: 190px;
                }
            }
        }
    }
    .contents {
        color: $gray-700;
        font-size: px2rem(16);
        line-height: px2rem(24);
        text-align: center;

        h3 {
            color: $gray-800;
            font-size: px2rem(36);
            font-weight: bold;
            line-height: px2rem(40);
        }
    }
}
</style>
