<template>
    <div v-if="pinEnabled && isNewSiteEnabled">
        <DesignV2 />
    </div>
    <div v-else-if="pinEnabled" class="d-flex flex-column">
        <container-hero ref="containerHero" />

        <container-value-props @get-started-action="getStartedAction" />

        <container-real-payments @get-started-action="getStartedAction" />

        <container-new-car @get-started-action="getStartedAction" />

        <!-- This was set to false as a quick fix for https://carsaver.clickup.com/t/43207413/CS-2410 -->
        <container-warranty v-if="false" @get-started-action="getStartedAction" />

        <container-upgrade @get-started-action="getStartedAction" />

        <container-life-time-warranty v-if="showContainerLifeTimeWarranty" @get-started-action="getStartedAction" />

        <find-my-pin-modal></find-my-pin-modal>
    </div>
    <plugin-landing-page v-else />
</template>
<script>
import FindMyPinModal from "../components/FindMyPinModal";
import ContainerHero from "../components/Home/ContainerHero/index";
import ContainerValueProps from "../components/Home/ContainerValueProps";
import ContainerWarranty from "../components/Home/ContainerWarranty";
import ContainerNewCar from "../components/Home/ContainerNewCar";
import ContainerRealPayments from "../components/Home/ContainerRealPayments";
import ContainerUpgrade from "../components/Home/ContainerUpgrade";
import ContainerLifeTimeWarranty from "../components/Home/ContainerLifeTimeWarranty";
import PluginLandingPage from "Modules/Profile/views/PluginLandingPage";
import { get } from "vuex-pathify";
import lodashGet from "lodash/get";
import DesignV2 from "Modules/Home/components/Home/DesignV2/index.vue";
import { isNewUpgradeTheme } from "@/libs/themeUtil";

export default {
    name: "LandingPage",
    components: {
        DesignV2,
        PluginLandingPage,
        FindMyPinModal,
        ContainerHero,
        ContainerValueProps,
        ContainerWarranty,
        ContainerNewCar,
        ContainerRealPayments,
        ContainerUpgrade,
        ContainerLifeTimeWarranty,
    },
    computed: {
        pinEnabled: get("campaign/pinEnabled"),
        isWarrantyEnabled: get("campaign/warrantyEnabled"),
        theme: get("campaign/theme"),
        featureFlags: get("context/featureFlags"),
        showContainerLifeTimeWarranty() {
            return this.isWarrantyEnabled;
        },
        isNewSiteEnabled() {
            return isNewUpgradeTheme(this.theme);
        },
    },
    mounted() {
        this.initEventListeners();
    },
    methods: {
        getStartedAction() {
            this.$refs.containerHero.getStartedAction();
        },
        initEventListeners() {
            this.$root.$on("get-started-action", this.getStartedAction);
        },
    },
};
</script>
<style lang="scss"></style>
