<template>
    <section class="all-offer">
        <!-- eslint-disable vue/no-v-html -->
        <h2 v-html="$t('home.offerSection.title')" />
        <div class="list-container">
            <div v-for="item in $t('home.offerSection.offers')" :key="item.title" class="list-item">
                <div :class="[item.imgClass, 'img-style']">&#8203;</div>
                <div class="item-content">
                    <p>{{ item.title }}</p>
                </div>
            </div>
        </div>
        <primary-btn class="primary-btn-style" @click.native="handleClick({})">
            {{ $t("home.offerSection.primaryCtaText") }}
        </primary-btn>
        <p>
            {{ $t("home.offerSection.footerDescription") }}
        </p>
    </section>
</template>

<script>
import { defineComponent } from "vue";
import PrimaryBtn from "Components/Buttons/PrimaryBtn.vue";
import MfaRedirectionMixin from "Modules/Home/components/Home/MfaRedirectionMixin.vue";
import { clientSessionStorage } from "@/libs/clientStorage";

export default defineComponent({
    name: "AllOffer",
    components: { PrimaryBtn },
    mixins: [MfaRedirectionMixin],
    methods: {
        handleClick(filters) {
            clientSessionStorage.setItem("SRP_FILTERS", JSON.stringify(filters));
            this._mixin_goToPageAction(); // MODIFIED IN MfaRedirectionMixin
        },
    },
});
</script>

<style scoped lang="scss">
$offers: (
    loyalty-search-new: "loyalty-search-new",
    loyalty-take-advantage: "loyalty-take-advantage",
    loyalty-transparent: "loyalty-transparent",
);

.all-offer {
    padding: 60px 16px 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #535353;

    h2 {
        text-align: center;
        font-size: px2rem(36);
        color: $secondary-gray;

        @include media-breakpoint-down("sm") {
            font-size: px2rem(28);
        }
    }

    .list-container {
        display: grid;
        grid-template-columns: repeat(3, minmax(198px, 322px));
        overflow: hidden;
        padding: 64px 0;

        width: 100%;
        max-width: 1108px;
        place-content: space-between;

        @include media-breakpoint-down("lg") {
            max-width: 960px;
            grid-template-columns: repeat(3, minmax(198px, 322px));
        }

        @include media-breakpoint-down("sm") {
            max-width: 350px;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            row-gap: 50px;
        }

        .list-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;

            .item-content {
                p {
                    margin: 0;
                    font-size: px2rem(16);
                    line-height: normal;
                    text-align: center;
                }
            }

            .img-style {
                width: 70px;
            }

            @each $class, $theme in $offers {
                .#{$class} {
                    @include themify($themes) {
                        background-image: themed("#{$theme}");
                        background-size: cover;
                        display: inline-block;
                        width: 70px;
                        height: 70px;
                        transition: transform 0.3s ease;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    p {
        margin: 32px 0 0 0;
        font-size: px2rem(11);
        line-height: 15px;
        max-width: 1108px;
    }

    .primary-btn-style {
        display: block;
        width: 100%;
        max-width: 303px;

        button {
            width: 100%;
        }
    }
}
</style>
