<template>
    <div class="realtrade-container">
        <div class="image">
            <b-container class="d-none d-sm-block">
                <div class="contents d-none d-sm-block">
                    <h3 class="mb-2">Get a Real Trade Offer</h3>
                    <p>
                        No more questioning if your trade will be accepted. Receive a fully guaranteed offer for your
                        trade – not just an estimate.
                    </p>
                    <div class="cta">
                        <get-started-btn cs-tracker-event="getRealTradeOfferGetStarted" message="Estimate My Trade" />
                    </div>
                </div>
            </b-container>
        </div>
        <div class="contents d-sm-none px-4">
            <h3 class="mt-4 mb-2">Get a Real<br />Trade Offer</h3>
            <p>
                No more questioning if your trade will be accepted. Receive a fully guaranteed offer for your trade –
                not just an estimate.
            </p>
        </div>
    </div>
</template>

<script>
import getStartedBtn from "./GetStartedBtn";
export default {
    components: { getStartedBtn },
};
</script>

<style lang="scss">
.realtrade-container {
    width: 100%;

    .image {
        background: url("../images/trade_bg.jpg") no-repeat top;
        background-size: cover;
        height: 194px;
        width: 100%;
        position: relative;

        @include media-breakpoint-up("sm") {
            background: url("../images/trade_bg_lg.jpg") no-repeat top right;
            height: 500px;
        }

        .contents {
            position: absolute;
            right: 100px;
            top: 150px;
            text-align: left;
            max-width: 430px;

            h3 {
                color: $gray-800;
                font-size: px2rem(36);
                font-weight: bold;
                line-height: px2rem(43);
            }
            .cta {
                button {
                    width: 190px;
                }
            }
        }
    }
    .contents {
        color: $gray-700;
        font-size: px2rem(16);
        line-height: px2rem(24);
        text-align: center;

        h3 {
            color: $gray-800;
            font-size: px2rem(36);
            font-weight: bold;
            line-height: px2rem(40);
        }
    }
}
</style>
