<template>
    <div class="car-aos">
        <b-img
            :src="howItWorksDrivetrain"
            alt="Drivetrain"
            class="drivetrain"
            data-aos="fade-right"
            data-aos-delay="0"
            :data-aos-anchor="'#lifetime-warranty-img-' + anchorPrefix + '-container'"
            data-aos-anchor-placement="bottom-bottom"
        />
        <b-img
            :src="howItWorksEngine"
            alt="Engine"
            class="engine"
            data-aos="fade-right"
            data-aos-delay="100"
            :data-aos-anchor="'#lifetime-warranty-img-' + anchorPrefix + '-container'"
            data-aos-anchor-placement="bottom-bottom"
        />
        <b-img
            :src="howItWorksTransmission"
            alt="Transmission"
            class="transmission"
            data-aos="fade-right"
            data-aos-delay="200"
            :data-aos-anchor="'#lifetime-warranty-img-' + anchorPrefix + '-container'"
            data-aos-anchor-placement="bottom-bottom"
        />
        <b-img :src="howItWorksCar" alt="Vehicle" class="car" />
    </div>
</template>

<script>
import AOS from "aos";
import "aos/src/sass/aos.scss";

import howItWorksDrivetrain from "./images/how-it-works-drivetrain.svg";
import howItWorksEngine from "./images/how-it-works-engine.svg";
import howItWorksTransmission from "./images/how-it-works-transmission.svg";
import howItWorksCar from "./images/how-it-works-car.svg";

export default {
    props: {
        anchorPrefix: {
            type: String,
            required: false,
            default: "non-mobile",
        },
    },
    data() {
        return {
            howItWorksDrivetrain: howItWorksDrivetrain,
            howItWorksEngine: howItWorksEngine,
            howItWorksTransmission: howItWorksTransmission,
            howItWorksCar: howItWorksCar,
        };
    },
    created() {
        AOS.init({
            duration: 600,
        });
    },
};
</script>

<style lang="scss">
.car-aos {
    display: table;
    position: relative;
    margin: 0 auto;

    .drivetrain {
        position: absolute;
        top: 12px;
        left: 36px;
        height: 107px;
    }

    .engine {
        position: absolute;
        left: 13px;
        top: 45px;
        height: 41px;
    }

    .transmission {
        position: absolute;
        left: 49px;
        top: 53px;
        height: 25px;
    }

    .car {
        height: 130px;
    }

    @include media-breakpoint-up(sm) {
        .drivetrain {
            position: absolute;
            top: 15px;
            left: 48px;
            height: 140px;
        }
        .engine {
            position: absolute;
            left: 17px;
            top: 61px;
            height: 50px;
        }
        .transmission {
            position: absolute;
            left: 61px;
            top: 70px;
            height: 32px;
        }
        .car {
            width: 325px;
            height: auto !important;
        }
    }
}
</style>
