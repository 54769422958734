
import Vue from "vue";
import { get } from "vuex-pathify";
import GetStartedBtn from "./GetStartedBtn.vue";
import PrimaryLink from "Components/Links/primaryLink.vue";
import CarAos from "Modules/HowItWorks/CarAos.vue";
import LifetimeWarrantyModal from "Components/LifetimeWarrantyModal/index.vue";

export default Vue.extend({
    components: { GetStartedBtn, PrimaryLink, CarAos, LifetimeWarrantyModal },
    data() {
        return {
            disclaimers: {
                disclaimer1: `*The CarSaver Lifetime Warranty is only available on eligible vehicles. CarSaver’s
                Lifetime Warranty covers all repairs to your engine, transmission, and
                drivetrain for your vehicle for as long as you own it. Parts of like kind and quality may be used for
                repairs, including remanufactured parts. You will be responsible for any charges not covered under your
                contract, including maintenance work or non-covered repairs. CarSaver’s Lifetime Warranty only
                covers claims that would not be covered by the manufacturer’s warranty. CarSaver’s Lifetime Warranty
                may only be obtained through a CarSaver Certified Dealer and in compliance with CarSaver policies.
                See the CarSaver Limited Warranty Agreement for complete details, which is available from your
                CarSaver dealer prior to obtaining the warranty. Effective March 1, 2021, before coverage for
                maintenance begins, there is a waiting period of 4 months and 4,000 miles (30 days and 1,000 miles
                in Georgia) after the effective date of the service contract. See `,
                disclaimer2: ` for vehicles eligible for a lifetime warranty or contact your CarSaver dealer to learn
                about your particular vehicle’s eligibility. You can also view frequently asked questions regarding this
                product at `,
                disclaimer3: ` contact our customer support at`,
                disclaimer4: ` with any specific questions.`,
            },
        };
    },
    computed: {
        campaignDomain: get("campaign/domain"),
        campaignURL(): string {
            return "https://" + this.campaignDomain;
        },
        faqLink(): string {
            return this.campaignURL + "/warranty/faq";
        },
        supportPhoneNo: get("campaign/phoneNumberFormatted"),
        mediaQueryType(): string {
            // @ts-ignore
            return this.$mq;
        },
        translate() {
            // @ts-ignore
            return this.$t;
        },
    },
    methods: {
        showLTWModal() {
            // @ts-ignore
            this.$bvModal.show("life-time-warranty-modal");
        },
    },
});
