<template>
    <div>
        <b-container fluid class="upgrade-at-home-container">
            <b-container class="text-block d-none d-md-block">
                <b-row>
                    <b-col cols="12">
                        <div class="d-flex flex-column">
                            <!-- eslint-disable vue/no-v-html -->
                            <div class="heading pb-3">
                                <h2>{{ $t("home.upgrade.title") }}</h2>
                            </div>

                            <div class="msg" v-html="$t('home.upgrade.message')"></div>
                            <!-- eslint-enable vue/no-v-html -->

                            <get-started-btn class="learn-more-btn" :text="$t('home.upgrade.buttonText')" />
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>

        <b-container class="d-block d-md-none">
            <b-row>
                <b-col cols="12">
                    <div class="d-flex flex-column px-3 pb-0 mobile-text-block">
                        <!-- eslint-disable vue/no-v-html -->
                        <div class="heading pb-3">
                            <h2>{{ $t("home.upgrade.title") }}</h2>
                        </div>

                        <div class="msg" v-html="$t('home.upgrade.message')"></div>
                        <!-- eslint-enable vue/no-v-html -->

                        <get-started-btn class="learn-more-btn" :text="$t('home.upgrade.buttonText')" />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { get } from "vuex-pathify";
import GetStartedBtn from "./GetStartedBtn";

export default {
    components: { GetStartedBtn },
    data() {
        return {
            msg: "Nissan Buy@Home gives you the power to shop for a new or used car online, right from your home.",
            buttonText: "Start Shopping",
        };
    },
    computed: {
        isLoggedIn: get("context/isLoggedIn"),
    },
};
</script>
<style lang="scss" scoped>
.upgrade-at-home-container {
    min-height: 220px;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;

    .text-block {
        color: white;
        position: relative;
        z-index: 2;
        padding-top: 150px;
        @include themify($themes) {
            color: themed("upgrade-at-home-color");
        }

        .msg {
            font-size: px2rem(16);
            line-height: 1.3;
            max-width: 405px;
        }

        .learn-more-btn {
            margin-top: 30px;
            width: 250px !important;
        }
    }

    .heading {
        h1,
        h2 {
            margin: 0;
        }
        h1 {
            font-size: px2rem(36);
            font-weight: bold;
            line-height: 1.3;
            font-style: normal;
        }
        h2 {
            font-size: px2rem(36);
            line-height: 1.3;
            margin-bottom: 0;
            font-style: normal;
            font-weight: bold;
        }
    }

    @include themify($themes) {
        background-image: themed("upgrade-at-home-sm");
    }

    @include media-breakpoint-up("md") {
        min-height: 508px;

        @include themify($themes) {
            background-image: themed("upgrade-at-home-lg");
        }
    }
}

.mobile-text-block {
    padding-top: 60px;
    padding-bottom: 38px;
    color: black;

    .heading {
        h1,
        h2 {
            margin: 0;
        }
        h1 {
            font-size: px2rem(36);
            font-weight: bold;
            line-height: 1.3;
            font-style: normal;
        }
        h2 {
            font-size: px2rem(28);
            line-height: 1.3;
            margin-bottom: 0;
            font-style: normal;
            font-weight: bold;
        }
    }

    .msg {
        font-size: px2rem(16);
        line-height: 1.3;
        max-width: 355px;
    }

    .learn-more-btn {
        margin-top: 30px;
        width: 100%;
    }
}
</style>
