<template>
    <div ref="containerHeroPinForm" class="hero-content">
        <b-row v-if="displayNoPinProspectWelcome" class="hero-no-pin-prospect-row-wrapper p-4" no-gutters>
            <b-col class="mb-2" cols="12">
                <div id="no-pin-prospect-label">Welcome to your personal vehicle upgrade website.</div>
            </b-col>
            <b-col class="mt-3 mr-4" cols="6">
                <primary-btn
                    class="get-started-btn"
                    :class="{ 'new-form-get-started-btn': newPinForm }"
                    @click.native="getStartedAction"
                >
                    {{ pinButtonText }}
                </primary-btn>
            </b-col>
        </b-row>
        <b-row v-else class="hero-row-wrapper hero-wrapper-bg" :class="{ 'p-4 margin-bottom': !newPinForm }" no-gutters>
            <b-col class="message-container mb-2" cols="12">
                <!-- eslint-disable vue/no-v-html -->
                <div id="pin-label" :class="getPinLabelClass" v-html="title"></div>
                <template v-if="!newPinForm">
                    <div v-if="!pinValidated" id="pin-sub-label" :class="{ 'max-width--303': newPinForm }">
                        {{ subTitle }}
                    </div>
                    <div v-else id="pin-message" :class="{ 'max-width--303': newPinForm }">
                        {{ pinMessage }}
                    </div>
                </template>
                <div v-if="!displayPinForm" class="mt-3">
                    <b-button
                        class="get-started-btn"
                        :class="{ 'new-form-get-started-btn': newPinForm }"
                        variant="primary"
                        @click="goToPage"
                    >
                        {{ pinButtonText }}
                    </b-button>
                    <b-button
                        v-if="!isLoggedIn && !pinInUrl"
                        class="mt-2 w-100 pin-btn"
                        :class="{ 'new-form-get-started-btn': newPinForm }"
                        @click="showPinBox = true"
                    >
                        {{ haveAPinButtonText }}
                    </b-button>
                </div>
            </b-col>
            <b-col v-if="displayPinForm" cols="12">
                <pin-form ref="pinForm" />
            </b-col>
            <b-col v-if="displayPinForm" cols="12">
                <div class="d-flex">
                    <primary-link v-b-modal.find-my-pin class="hero-link"> Find Your PIN</primary-link>
                    <primary-link class="ml-3 hero-link" href="/profile/no-pin-welcome">
                        Don’t have a PIN?
                    </primary-link>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import PrimaryBtn from "Components/Buttons/PrimaryBtn.vue";
import PrimaryLink from "Components/Links/primaryLink.vue";
import PinForm from "Modules/Home/components/Home/ContainerHero/PinForm.vue";
import { get } from "vuex-pathify";
import lodashIsEmpty from "lodash/isEmpty";
import lodashGet from "lodash/get";
import CampaignProgramEnum from "@/libs/CampaignProgramEnum";
import MfaRedirectionMixin from "Modules/Home/components/Home/MfaRedirectionMixin.vue";

export default defineComponent({
    name: "PinFormContainer",
    components: { PrimaryBtn, PinForm, PrimaryLink },
    mixins: [MfaRedirectionMixin],
    props: {
        newPinForm: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data: function () {
        return {
            noPinProspectTitle: "Welcome to your personal vehicle upgrade website",
            subTitle: "The easiest way to buy or upgrade to a newer car.",
            showPinBox: false,
        };
    },
    computed: {
        pinEnabled: get("campaign/pinEnabled"),
        isLoggedIn: get("context/isLoggedIn"),
        firstName: get("context/firstName"),
        pinValidated: get("context/pinValidated"),
        userExistForProspect: get("context/userExistForProspect"),
        autoSendMFA: get("context/autoSendMFA"),
        isMember: get("context/isMember"),
        isMemberDisabled: get("context/isMemberDisabled"),
        featureFlags: get("context/featureFlags"),
        userExist() {
            if (this.isMember) {
                return this.isMemberDisabled;
            } else {
                return !!this.userExistForProspect;
            }
        },
        hasFirstName() {
            return !lodashIsEmpty(this.firstName);
        },
        requestPin() {
            return !(this.pinEnabled === false || this.isLoggedIn);
        },
        title() {
            if (this.hasFirstName || this.pinValidated || this.isLoggedIn) {
                return this.newPinForm
                    ? this.$t("home.upgrade.pinTextNew", [this.firstName])
                    : `<em>Hello, ${this.firstName}!</em>`;
            }
            if (this.requestPin) {
                return this.$t("home.upgrade.pinText");
            } else {
                return "Welcome Back!";
            }
        },
        pinMessage() {
            return this.$t("home.pinForm.pinMessage");
        },
        pinButtonText() {
            return this.pinValidated || this.isLoggedIn ? this.$t("home.pinForm.pinButtonText") : "Get Started";
        },
        noPinProspect() {
            return lodashGet(this.$route.query, "noPinProspect");
        },
        displayNoPinProspectWelcome() {
            let noPinTrue = this.noPinProspect === true || this.noPinProspect === "true";
            let noPinPresent = this.noPinProspect === null;
            return !this.hasFirstName && (noPinTrue || noPinPresent);
        },
        pinErrorInUrl() {
            return lodashGet(this.$route.query, "error");
        },
        pinInUrl() {
            return lodashGet(this.$route.query, "pin");
        },

        theme: get("campaign/theme"),
        programId: get("campaign/programId"),
        isBMWProgram() {
            return CampaignProgramEnum.BMW.indexOf(this.programId) !== -1;
        },
        displayPinForm() {
            const enablePinForm = this.pinEnabled && !this.isLoggedIn && this.pinErrorInUrl;
            return (enablePinForm || this.showPinBox) && !this.pinInUrl;
        },
        isNonPinnedReducedSignUpFlowEnabled() {
            return lodashGet(this.featureFlags, "NON_PINNED_REDUCED_SIGN_UP_FLOW", false) || false;
        },
        getPinLabelClass() {
            if (this.newPinForm) return `font-style-new max-width--303`;
            return "font-style";
        },
        haveAPinButtonText() {
            return this.newPinForm ? "Have a PIN?" : "Have a Pin? Click Here.";
        },
    },
    watch: {
        displayPinForm(newVal) {
            this.$store.commit("context/SET_DISPLAY_PIN_FORM", newVal);
        },
    },
    methods: {
        checkNoPinProspectAction() {
            if (this.displayNoPinProspectWelcome) {
                window.location = "/account/register";
            }
        },
        // TODO move logic to the backend
        goToPage() {
            this._mixin_goToPageAction(); // MODIFIED IN MfaRedirectionMixin
        },
        getStartedAction() {
            this.checkNoPinProspectAction();

            if (this.displayPinForm) {
                this.$refs.pinForm.submitForm();
                const containerHeroPinForm = this.$refs.containerHeroPinForm;

                if (containerHeroPinForm) {
                    containerHeroPinForm.scrollIntoView({ behavior: "smooth" });
                }
            } else {
                this.goToPage();
            }
        },
    },
});
</script>

<style scoped lang="scss">
.hero-content {
    .heading {
        @include themify($themes) {
            color: themed("container-hero-text-color");
        }
        margin-bottom: 18px;

        h1 {
            font-size: px2rem(32);
            font-weight: bold;
            line-height: px2rem(43);
        }

        h2 {
            font-size: px2rem(24);
            font-size: 1.5rem;
            line-height: 1.4;
            margin-bottom: 0;
        }
    }

    .hero-wrapper-bg {
        @include themify($themes) {
            background-color: themed("hero-wrapper-bg-color") !important;
        }
    }

    .margin-bottom {
        margin-bottom: 75px;
    }

    .max-width--303 {
        max-width: 303px !important;
    }

    .hero-row-wrapper {
        @include media-breakpoint-up("sm") {
            max-width: 345px;
        }

        border-radius: 2px;

        #pin-label {
            font-weight: bold;
            line-height: px2rem(43);
            max-width: 290px;
            @include themify($themes) {
                color: themed("container-hero-text-color");
            }
        }

        #pin-sub-label {
            font-size: px2rem(22);
            margin-top: 7px;
            max-width: 290px;
            @include themify($themes) {
                color: themed("container-hero-text-color");
            }
        }

        .font-style-new {
            font-size: px2rem(36) !important;
            font-weight: normal !important;
            line-height: normal !important;

            @include media-breakpoint-down("sm") {
                font-size: px2rem(32) !important;
            }
        }
        .font-style {
            font-size: px2rem(32) !important;
        }

        #pin-message {
            font-size: px2rem(24);
            max-width: 290px;
            @include themify($themes) {
                color: themed("container-hero-text-color");
            }
        }

        .hero-link {
            font-size: px2rem(12);
            line-height: px2rem(14);
            text-decoration: underline;
            @include themify($themes) {
                color: themed(link-color);
            }

            &:hover {
                @include themify($themes) {
                    color: darken(themed("primary-color"), 10%);
                }
            }
        }

        .get-started-btn {
            width: 100%;
            border-radius: 4px;
        }

        .new-form-get-started-btn {
            font-size: px2rem(16);
            height: 48px;
        }

        .pin-btn {
            border-radius: 4px;
            border-width: 2px;
            @include themify($themes) {
                background-color: themed("secondary-color");
            }
        }
    }

    .hero-no-pin-prospect-row-wrapper {
        max-width: 500px;
        margin-bottom: 75px;
        border-radius: 2px;
        @include themify($themes) {
            background-color: themed("hero-wrapper-bg-color");
        }

        #no-pin-prospect-label {
            font-size: px2rem(36);
            font-weight: bold;
            line-height: px2rem(43);
            @include themify($themes) {
                color: themed("container-hero-text-color");
            }
        }

        .get-started-btn {
            min-width: 100%;
            border-radius: 4px;
        }
    }
}
</style>
