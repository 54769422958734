import Vue from "vue";
import VueRouter from "vue-router";
import Home from "./views/Home";
import { configureRouter, routerOptions } from "@/libs/routerHelper";
import metaHelper from "@/libs/metaHelper";
import lodashGet from "lodash/get";

const PAGE_TITLE_PREFIX = metaHelper.pageTitlePrefix || "Upgrade";

Vue.use(VueRouter);

const PATH_PREFIX = "/";
const PATH_PREFIX_CUNEXUS = "/cunexus";
const dealerNameHome = lodashGet(window, "_CONTEXT.navBrand") || "Home";

// NOTE: Routes defined in the Home Module must also be defined in HomeController.java
const routes = [
    {
        path: PATH_PREFIX,
        name: "home",
        component: Home,
        meta: {
            gtm: "Home",
            title: PAGE_TITLE_PREFIX + " - " + dealerNameHome,
            // NOTE: Here is how to set meta descriptions
            // metaTags: [
            //     {
            //         name: "description",
            //         content: "CarSaver Vehicle Upgrade Program.",
            //     },
            //     {
            //         property: "og:description",
            //         content: "CarSaver Vehicle Upgrade Program.",
            //     },
            // ],
        },
    },
    {
        path: PATH_PREFIX_CUNEXUS,
        name: "home",
        component: Home,
        meta: {
            gtm: "Home",
            title: PAGE_TITLE_PREFIX + " - " + dealerNameHome,
        },
    },
    {
        path: "/how-it-works",
        name: "how-it-works-pin",
        component: () => import(/* webpackChunkName: "how-it-works-pin" */ "./views/HowItWorksPin.vue"),
        meta: {
            gtm: "How It Works Pin",
            title: PAGE_TITLE_PREFIX + " - How It Works Pin",
        },
    },
    {
        path: "/page-not-found",
        name: "page-not-found",
        component: () => import(/* webpackChunkName: "page-not-found" */ "Components/PageNotFound.vue"),
        meta: {
            gtm: "Page Not Found",
            title: "Page Not Found",
        },
    },
    {
        path: "/common-components",
        name: "common-components",
        component: () => import(/* webpackChunkName: "common-components" */ "./views/CommonComponents.vue"),
        meta: {
            gtm: "Common Components",
            title: PAGE_TITLE_PREFIX + " - Common Components",
        },
    },
    {
        path: "/tos",
        name: "tos",
        component: () => import(/* webpackChunkName: "tos" */ "./views/TermsOfService.vue"),
        meta: {
            gtm: "Terms of Service",
            title: PAGE_TITLE_PREFIX + " - Terms of Service",
        },
    },
    {
        path: "/privacy",
        name: "privacy",
        component: () => import(/* webpackChunkName: "privacy" */ "./views/PrivacyPolicy.vue"),
        meta: {
            gtm: "Privacy Policy",
            title: PAGE_TITLE_PREFIX + " - Privacy Policy",
        },
    },
    {
        path: "/california-privacy-policy",
        name: "california-privacy-policy",
        component: () => import(/* webpackChunkName: "privacy" */ "./views/CaliforniaPrivacyPolicy.vue"),
        meta: {
            gtm: "California Privacy Policy",
            title: PAGE_TITLE_PREFIX + " - California Privacy Policy",
        },
    },
    {
        path: "/aww-snap",
        alias: "*",
        name: "aww-snap",
        component: () => import(/* webpackChunkName: "error" */ "../AwwSnap/index"),
        meta: {
            gtm: "Aww Snap",
            title: PAGE_TITLE_PREFIX + " - Aww Snap",
        },
    },
    {
        path: "/warranty",
        name: "warranty",
        component: () => import(/* webpackChunkName: "warranty" */ "./views/Warranty.vue"),
        meta: {
            gtm: "Warranty",
            title: PAGE_TITLE_PREFIX + " - Warranty",
        },
    },
    {
        path: "/warranty/faq",
        name: "warranty-faq",
        component: () => import(/* webpackChunkName: "warranty" */ "./views/WarrantyFaq.vue"),
        meta: {
            gtm: "warranty faq",
            title: PAGE_TITLE_PREFIX + " - warranty faq",
        },
    },
    {
        path: "/insure",
        name: "insure",
        component: () => import(/* webpackChunkName: "warranty" */ "./views/Insure.vue"),
        meta: {
            gtm: "Insure",
            title: PAGE_TITLE_PREFIX + " - Insure",
        },
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
    ...routerOptions,
});

configureRouter(router, PATH_PREFIX);

export default router;
