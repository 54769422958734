<template>
    <modal
        id="find-my-pin"
        size="sm"
        title="Locate your pin"
        subtitle="On your upgrade invitation mailer, the PIN is in red in the center."
        centered
        ok-only
        hide-header-close
    >
        <div class="body-context">
            <div class="sample-mailer position-relative mb-4">
                <div class="mailer">
                    <div class="mailer-header d-flex flex-column justify-content-center align-items-center mb-4">
                        <p class="header-subtitle p-0 m-0">Welcome to the</p>
                        <p v-t="'campaign.title'" class="header-title p-0 m-0">CarSaver Upgrade Program</p>
                    </div>
                    <div class="pin-sample">
                        <p class="welcome-msg p-0 m-0">Hi Diana, here is your PIN</p>
                        <p class="pin p-0 m-0">1234</p>
                        <p class="description p-0 m-0">Your PIN</p>
                    </div>
                    <div class="mailer-footer">
                        You have been invited to check out brand new vehicles with enhanced performance and safety
                        features, and improved fuel economy. Visit your Personalized Portal today, or call us at
                        {{ phoneNumberFormatted }} to schedule an appointment.
                    </div>
                </div>
                <div class="sample-overlay position-absolute">SAMPLE IMAGE</div>
                <div class="terminating-overlay"></div>
            </div>

            <div class="footer-context">
                <div>
                    <span class="footer-title">Need Help?</span>
                    <p class="footer-body">
                        Call customer service at
                        <primary-link class="contact-number" :href="'tel:' + phoneNumber">
                            {{ serviceNumber }}
                        </primary-link>
                    </p>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "Components/Modal";
import { get } from "vuex-pathify";
import PrimaryLink from "Components/Links/primaryLink";
import CampaignProgramEnum from "@/libs/CampaignProgramEnum";

export default {
    name: "FindMyPinModal",

    components: { Modal, PrimaryLink },

    computed: {
        phoneNumberFormatted: get("campaign/phoneNumberFormatted"),
        phoneNumber: get("campaign/phoneNumber"),
        theme: get("campaign/theme"),
        programId: get("campaign/programId"),
        isBMWProduct() {
            return CampaignProgramEnum.BMW.indexOf(this.programId) !== -1;
        },
        serviceNumber() {
            if (!this.isBMWProduct) {
                return this.phoneNumberFormatted;
            } else {
                return this.$t("home.upgrade.technicalPhoneNumber");
            }
        },
    },
};
</script>

<style lang="scss">
#find-my-pin {
    .modal-body {
        padding-top: 8px;
        padding-bottom: 20px;
    }
    .sample-mailer {
        .mailer {
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);

            &-header {
                height: 94px;
                background-color: black;
                color: white;

                .header-title {
                    font-size: px2rem(14);
                    font-weight: bold;
                    line-height: 19px;
                }

                .header-subtitle {
                    font-size: px2rem(10);
                    font-weight: bold;
                    line-height: 13px;
                }
            }

            .pin-sample {
                text-align: center;
                margin-bottom: 20px;

                .welcome-msg {
                    color: #2e2e2e;
                    font-size: px2rem(10);
                    font-weight: bold;
                    line-height: 13px;
                }
                .pin {
                    color: #c3002f;
                    font-size: px2rem(28);
                    line-height: 36px;
                }
                .description {
                    color: $gray-500;
                    font-size: px2rem(8);
                    font-weight: bold;
                    line-height: 10px;
                }
            }

            .mailer-footer {
                width: 232px;
                color: #535353;
                font-size: px2rem(10);
                line-height: 13px;
                margin: 0 auto;
                padding: 0;
            }
        }

        .sample-overlay {
            transform: rotate(-45deg);
            color: #504f4f52;
            font-size: px2rem(36);
            font-weight: bold;
            line-height: 46px;
            text-align: center;
            top: 40%;
            width: 100%;
        }

        .terminating-overlay {
            position: absolute;
            top: 0;
            height: 102%;
            width: 304px;
            z-index: 9999;
            left: 0;
            margin-left: -10px;
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 64%, rgba(255, 255, 255, 1));
        }
    }
    .modal-titles {
        display: flex;
        flex-direction: column;
        align-items: center;

        .modal-title {
            color: #2e2e2e;
            margin-bottom: 6px;
        }

        .modal-subtitle {
            max-width: 255px;
            color: #888;
        }
    }

    .footer-context {
        text-align: center;
        color: #4c4c4c;

        .footer-title {
            font-size: px2rem(14);
            line-height: 19px;
            font-weight: bold;
        }

        .footer-body {
            font-size: px2rem(12);
            line-height: 15px;
            padding: 0;
            margin: 0;
            color: #888;
        }

        .contact-number {
            font-weight: bold;

            @include themify($themes) {
                color: themed("primary-color");
            }
        }
    }
}
</style>
