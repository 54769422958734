<template>
    <b-container fluid class="container-value-props d-flex justify-content-md-center align-items-md-center">
        <b-container>
            <b-row no-gutters class="value-prop-row">
                <b-col cols="12" lg="6" class="img-col d-flex align-items-center">
                    <div class="value-prop-img-container mb-5 mb-lg-0"></div>
                </b-col>
                <b-col cols="12" lg="6" class="text-col">
                    <div>
                        <h2 class="value-prop-title">
                            {{
                                $t("home.valueProps.title", {
                                    default: "The Easiest Way to Upgrade to a Newer Car.",
                                })
                            }}
                        </h2>

                        <div>
                            <div
                                v-for="(valueProp, index) in $t('home.valueProps.items')"
                                :key="index"
                                class="d-flex align-items-center mb-2"
                            >
                                <div class="circle-check d-flex justify-content-center align-items-center">
                                    <span class="material-icons" aria-hidden="true"> done </span>
                                </div>

                                <span class="value-prop ml-2">
                                    {{ valueProp }}
                                </span>
                            </div>
                        </div>
                        <get-started-btn class="get-started-btn mb-3" :text="buttonText" />
                        <div v-if="!isLoggedIn" class="text-center text-md-left">
                            <primary-link class="already-user-link" href="/account/login">
                                Already signed up?
                            </primary-link>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>
<script>
import { get } from "vuex-pathify";
import GetStartedBtn from "./GetStartedBtn";
import PrimaryLink from "Components/Links/primaryLink";
export default {
    components: { GetStartedBtn, PrimaryLink },
    computed: {
        isLoggedIn: get("context/isLoggedIn"),
        buttonText() {
            return this.$t("home.valueProps.buttonText");
        },
    },
};
</script>
<style lang="scss" scoped>
.container-value-props {
    padding-top: 65px;
    padding-bottom: 25px;
    .value-prop-row {
        .img-col {
            .value-prop-img-container {
                min-height: 145px;
                height: 100%;
                width: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;

                @include themify($themes) {
                    background-image: themed("value-prop-img-md");
                }
                @include media-breakpoint-up("lg") {
                    @include themify($themes) {
                        background-image: themed("value-prop-img-lg");
                    }
                }
            }
        }

        .text-col {
            .value-prop-title {
                max-width: 480px;
                color: $gray-800;
                font-size: px2rem(28);
                font-weight: bold;
                letter-spacing: 0;
                line-height: 1.3;
                margin-bottom: 30px;

                @include media-breakpoint-up(md) {
                    font-size: px2rem(36);
                }
            }

            .circle-check {
                height: 14px;
                width: 14px;

                .material-icons {
                    font-size: px2rem(10);
                    font-weight: bold;
                    @include themify($themes) {
                        border-radius: 50%;
                        border: 1px solid themed("primary-color");
                        color: themed("primary-color");
                    }
                }
            }

            .value-prop {
                color: $gray-700;
                font-size: px2rem(16);
                line-height: 1.3;
            }

            .get-started-btn {
                width: 100%;
                margin-top: 30px;
                width: 100%;
            }

            .already-user-link {
                font-size: px2rem(16);
                line-height: 1.3;
                @include themify($themes) {
                    color: themed("primary-color");
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .value-prop-row {
            .text-col {
                .get-started-btn {
                    width: 250px;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        height: 508px;
        padding-top: 0;
        padding-bottom: 0;

        .value-prop-row {
            .img-col {
                padding: 0 30px 0 0;
            }

            .text-col {
                padding: 0 0 0 30px;
            }
        }
    }
}
</style>
