<template>
    <div>
        <b-container class="real-payments-container d-md-flex align-items-md-center" fluid>
            <b-container class="desktop-text-block d-none d-md-flex justify-content-md-start">
                <b-row>
                    <b-col cols="12">
                        <div class="d-flex flex-column">
                            <h2 class="mb-3">{{ title }} {{ subtitle }}</h2>

                            <div class="msg">
                                {{ msg }}
                            </div>

                            <get-started-btn :text="linkText" class="offers-btn" />
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>

        <b-container class="d-block d-md-none">
            <b-row>
                <b-col cols="12">
                    <div class="d-flex flex-column px-3 mobile-text-block">
                        <h2>{{ title }}</h2>
                        <h2 class="mb-3">
                            {{ subtitle }}
                        </h2>

                        <div class="msg">
                            {{ msg }}
                        </div>

                        <get-started-btn :text="linkText" class="offers-btn" />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { get } from "vuex-pathify";
import GetStartedBtn from "./GetStartedBtn";

export default {
    components: { GetStartedBtn },
    data() {
        return {
            title: "Real Payments,",
            subtitle: "Real Cars",
            // msg:
            //     "See real " +
            //     this.$t("home.payments.name") +
            //     " payments without impacting your credit score! Your offers include taxes" +
            //     "& registration and the payoff on your current vehicle.",
        };
    },
    computed: {
        isLoggedIn: get("context/isLoggedIn"),
        theme: get("campaign/theme"),
        paymentsText() {
            return this.$t("home.payments.name");
        },
        msg() {
            return this.$t("home.payments.msg");
        },
        linkText() {
            return this.$t("home.payments.buttonText");
        },
    },
};
</script>
<style lang="scss" scoped>
.offers-btn {
    width: 100%;
}
.real-payments-container {
    min-height: 220px;
    padding-top: 65px;
    padding-bottom: 25px;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    @include themify($themes) {
        background-image: themed("real-payments-md");
    }

    @include media-breakpoint-up("lg") {
        min-height: 508px;
        background-position: center;

        @include themify($themes) {
            background-image: themed("real-payments-lg");
        }
    }

    @include media-breakpoint-down("sm") {
        @include themify($themes) {
            background-image: themed("real-payments-sm");
        }
    }
}

.real-payments-container {
    .desktop-text-block {
        @include themify($themes) {
            color: themed("real-payments-real-car-text-color");
        }

        h2 {
            font-size: px2rem(36);
            line-height: 1.3;
            font-weight: bold;
            margin-bottom: 0;
            max-width: 485px;
        }

        .offers-btn {
            margin-top: 30px;
            width: 250px;
        }

        .msg {
            font-size: px2rem(16);
            line-height: 1.3;
            max-width: 385px;
        }

        .action-link {
            font-size: px2rem(16);
            line-height: 1.3;
            @include themify($themes) {
                color: themed("primary-color");
            }
        }
    }
}

.mobile-text-block {
    padding-top: 38px;
    padding-bottom: 48px;
    color: $gray-800;

    .offers-btn {
        width: 100%;
    }

    h2 {
        font-size: px2rem(28);
        line-height: 1.3;
        font-weight: bold;
        margin-bottom: 0;
        max-width: 385px;
    }

    .msg {
        font-size: px2rem(16);
        line-height: 1.3;
        max-width: 535px;
    }

    .offers-btn {
        margin-top: 30px;
    }

    .action-link {
        font-size: px2rem(16);
        line-height: 1.3;
        @include themify($themes) {
            color: themed("primary-color");
        }
    }
}
</style>
