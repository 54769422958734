<template>
    <div class="plugin-landing-page">
        <containers />
        <vehicle-of-interest-modal :vehicle-id="vehicleOfInterestId" />
        <mobile-footer :vehicle-id="vehicleOfInterestId" />
    </div>
</template>

<script>
import { get } from "vuex-pathify";
import VehicleOfInterestModal from "Components/VehicleOfInterestModal";
import Containers from "Modules/Profile/components/PluginLandingPage/Containers";
import MobileFooter from "Modules/Profile/components/PluginLandingPage/MobileFooter";

export default {
    name: "PluginLandingPage",
    components: {
        Containers,
        VehicleOfInterestModal,
        MobileFooter,
    },
    computed: {
        vehicleOfInterestId: get("context/vehicleOfInterestId"),
    },
    mounted() {
        if (this.vehicleOfInterestId) {
            this.$bvModal.show("vehicle-of-interest-modal");
        }
    },
};
</script>
