<template>
    <primary-btn :loading="getStartedState.loading" @click.native="getStartedClick">
        {{ message }}
    </primary-btn>
</template>

<script>
import primaryBtn from "Components/Buttons/PrimaryBtn";
import CampaignProductEnum from "@/libs/CampaignProductEnum";
import lodashGet from "lodash/get";
import { get } from "vuex-pathify";

export default {
    name: "GetStartedBtn",
    components: { primaryBtn },
    props: {
        message: {
            type: String,
            required: false,
            default: "Get Started",
        },
        csTrackerEvent: {
            type: String,
            default: "buyAtHomeGetStarted",
        },
    },
    data() {
        return {
            getStartedState: {
                loading: false,
            },
        };
    },
    computed: {
        featureFlags: get("context/featureFlags"),
        productId: get("campaign/productId"),
        isGhostAccountRegisterFeature() {
            const result = lodashGet(this.featureFlags, "GHOST_ACCOUNT_REGISTER_FEATURE", false) || false;
            return result;
        },
        isECommerceProduct() {
            const result = this.productId === CampaignProductEnum.ECOMMERCE;
            return result;
        },
    },
    methods: {
        getStartedClick() {
            this.getStartedState.loading = true;
            this.$carsaverEventTracker(this.csTrackerEvent);
            if (this.isGhostAccountRegisterFeature === true && this.isECommerceProduct) {
                document.location = "/account/register/ghost";
            } else {
                document.location = "/account/register";
            }
        },
    },
};
</script>
