// current theme listing
// themes: [
//     carsaver,
//     ibank,
//     nissan,
// ]

const NEW_UPGRADE_THEME = Object.freeze(["bmw-v2", "nissanupgrade-v2"]);

export const isNewUpgradeTheme = (theme) => {
    return NEW_UPGRADE_THEME.includes(theme);
};

export default {
    // deprecated
    RebatesAndIncentives: {
        disabledTabs: {
            // exclusionary only
            ibank: ["finance", "lease"],
        },
    },

    welcomeVideos: {
        carsaver: "wistia_async_xc155jiqwn",
    },
};
