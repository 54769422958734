<template>
    <b-container fluid class="p-0">
        <div class="get-real-pricing-container">
            <div class="image d-flex justify-content-between">
                <div class="left-section d-none d-sm-inline-block mr-5"></div>
                <div class="content-container d-none d-sm-flex justify-content-center justify-content-xl-start">
                    <div class="content">
                        <h3 class="container-title">Get Real Pricing</h3>
                        <p>
                            With <span class="buyathome-logo">BUY</span>, you’ll have full pricing transparency. Get a
                            real price with applicable incentives, rebates, taxes and fees included.
                        </p>
                        <div class="cta">
                            <get-started-btn cs-tracker-event="getRealPricingGetStarted" />
                        </div>
                    </div>
                </div>
                <div class="right-section d-none d-xl-inline-block"></div>
            </div>
            <div class="content mobile-contents d-sm-none px-3">
                <h3 class="container-title mt-4">Get Real Pricing</h3>
                <p>
                    With <span class="buyathome-logo">BUY</span>, you’ll have full pricing transparency. Get a real
                    price with applicable incentives, rebates, taxes and fees included.
                </p>
            </div>
        </div>
    </b-container>
</template>

<script>
import getStartedBtn from "./GetStartedBtn";
export default {
    name: "GetRealPricing",
    components: { getStartedBtn },
};
</script>

<style lang="scss">
.get-real-pricing-container {
    width: 100%;

    .container-title {
        color: $gray-800;
        font-size: px2rem(36);
        font-weight: bold;
        line-height: px2rem(43);
    }

    .buyathome-logo {
        font-weight: bold;

        &:after {
            content: "@HOME";

            @include themify($themes) {
                color: themed("primary-color");
            }
        }
    }

    .image {
        background: url("../images/getrealpricing-bg-sm.jpg") no-repeat top;
        background-size: cover;
        height: 194px;
        width: 100%;

        @include media-breakpoint-up("sm") {
            height: 500px;
        }

        .left-section {
            min-width: 240px;
            max-width: 340px;
            width: 100%;

            @include media-breakpoint-up("sm") {
                background: url("../images/getrealpricing-bg-left-lg.jpg") no-repeat center;
                background-size: contain;
                height: 500px;
            }
        }
        .right-section {
            width: 550px;

            @include media-breakpoint-up("sm") {
                background: url("../images/getrealpricing-bg-right-lg.jpg") no-repeat;
                height: 500px;
            }
        }

        @include media-breakpoint-up("sm") {
            background: none;
        }

        .content-container {
            width: 50%;
            height: 100%;
            z-index: 1;
            padding-top: 130px;
            flex: 1;
            padding-right: 60px;

            @include media-breakpoint-up("xl") {
                width: 50px;
            }

            .content {
                width: 350px;
                text-align: left;

                @include media-breakpoint-up("lg") {
                    min-width: 408px;
                }

                .cta {
                    button {
                        width: 190px;
                    }
                }
            }
        }
    }
    .content {
        color: $gray-700;
        font-size: px2rem(16);
        line-height: px2rem(24);
        text-align: center;
    }
}
</style>
