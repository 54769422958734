<template>
    <div class="buyathome-container">
        <div class="image">
            <b-container class="d-none d-sm-block">
                <div class="contents">
                    <div class="logo-block mb-1">
                        <b-img :src="BuyAtHome" alt="NISSAN" />
                    </div>
                    <p>Explore every aspect of your vehicle purchase – at your own pace and convenience.</p>
                    <div class="cta">
                        <get-started-btn cs-tracker-event="buyAtHomeGetStarted" />
                    </div>
                </div>
            </b-container>
        </div>
        <div class="contents d-sm-none px-3 pb-2">
            <div class="d-flex justify-content-center">
                <div class="mobile-logo-block my-3">
                    <b-img :src="BuyAtHome" alt="NISSAN" />
                </div>
            </div>
            <p class="mb-2">Explore every aspect of your vehicle purchase – at your own pace and convenience.</p>
            <ul>
                <li>Get Real Pricing</li>
                <li>Get a Real Trade Offer</li>
                <li>Select Financing Options</li>
                <li>Save Your Work</li>
                <li>Complete Full Transaction</li>
            </ul>
        </div>
    </div>
</template>

<script>
import BuyAtHome from "../images/buyathome.png";
import GetStartedBtn from "./GetStartedBtn";
export default {
    name: "BuyAtHome",
    components: { GetStartedBtn },
    data() {
        return {
            BuyAtHome,
        };
    },
};
</script>

<style lang="scss">
.buyathome-container {
    width: 100%;

    .image {
        background: url("../images/buyathome_bg.jpg") no-repeat top;
        background-size: cover;
        height: 194px;
        width: 100%;

        @include media-breakpoint-up("sm") {
            background: url("../images/buyathome_bg_lg.jpg") no-repeat top;
            height: 500px;
        }

        .container > .contents {
            text-align: left;
            max-width: 350px;
            padding-top: 65px;
        }
    }
    .contents {
        color: $gray-700;
        font-size: px2rem(16);
        line-height: px2rem(24);
        text-align: center;

        .logo-block {
            width: 275px;

            img {
                width: 100%;
            }
        }

        .mobile-logo-block {
            width: 200px;
            img {
                width: 100%;
            }
        }

        .cta {
            button {
                width: 190px;
            }
        }

        ul {
            max-width: 230px;
            text-align: left;
            margin: 0 auto;
            font-weight: bold;
            font-size: px2rem(14);
            font-style: oblique;
            padding-left: 24px;
            list-style: none;

            li:before {
                content: "\2022";
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
                font-size: px2rem(20);
                vertical-align: bottom;
                @include themify($themes) {
                    color: themed("primary-color");
                }
            }
        }
    }
}
</style>
