<template>
    <div class="d-flex flex-column">
        <buy-at-home />
        <get-real-pricing />
        <real-trade />
        <finance />
        <save-work />
    </div>
</template>
<script>
import BuyAtHome from "./components/BuyAtHome";
import RealTrade from "./components/RealTrade";
import Finance from "./components/Finance";
import SaveWork from "./components/SaveWork";
import GetRealPricing from "./components/GetRealPricing";

export default {
    name: "Containers",
    components: {
        GetRealPricing,
        BuyAtHome,
        RealTrade,
        Finance,
        SaveWork,
    },
};
</script>
